var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-form" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.queryParams }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "充值订单ID：" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.queryParams.ID,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParams, "ID", $$v)
                      },
                      expression: "queryParams.ID"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客户名称：" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.queryParams.name,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParams, "name", $$v)
                      },
                      expression: "queryParams.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "模式" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.queryParams.model,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParams, "model", $$v)
                        },
                        expression: "queryParams.model"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "0",
                        attrs: { label: "全部", value: 0 }
                      }),
                      _c("el-option", {
                        key: "1",
                        attrs: { label: "预充金", value: 1 }
                      }),
                      _c("el-option", {
                        key: "2",
                        attrs: { label: "授信额度", value: 2 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "充值时间：" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { "margin-top": "6px", width: "100%" },
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss"
                        },
                        model: {
                          value: _vm.rechargeTime,
                          callback: function($$v) {
                            _vm.rechargeTime = $$v
                          },
                          expression: "rechargeTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.requestData }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleEdit(null)
                        }
                      }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-download" },
                      on: {
                        click: function($event) {
                          return _vm.onExoprt()
                        }
                      }
                    },
                    [_vm._v("导出")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.data, border: "", stripe: "" }
        },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "充值ID" } }),
          _c("el-table-column", { attrs: { prop: "name", label: "客户名称" } }),
          _c("el-table-column", {
            attrs: { prop: "model", label: "模式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.model === 2 ? "授信" : "预充金"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "before_amount", label: "变动前金额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s((scope.row.before_amount / 100).toFixed(2)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "充值金额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s((scope.row.change_amount / 100).toFixed(2)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "余额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s((scope.row.after_amount / 100).toFixed(2)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "times", label: "充值时间" }
          }),
          _c("el-table-column", {
            attrs: { label: "凭证", width: "130px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: {
                          src: scope.row.voucher,
                          "preview-src-list": [scope.row.voucher]
                        }
                      },
                      [
                        _c("div", {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error"
                        })
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "remark",
              label: "备注",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.queryParams.page,
          "page-sizes": [20, 50, 100, 200],
          "page-size": _vm.queryParams.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total
        },
        on: {
          "size-change": _vm.requestData,
          "current-change": _vm.requestData,
          "update:currentPage": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:current-page": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:pageSize": function($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          "update:page-size": function($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          }
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "增加/修改",
            visible: _vm.dialogNewVisible,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogNewVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "newForm",
              attrs: {
                model: _vm.newForm,
                rules: _vm.rules,
                "label-width": "100px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户名称：", prop: "name" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "60%" },
                      attrs: {
                        disabled: _vm.newForm.id > 0,
                        filterable: "",
                        clearable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "请输入客户名称",
                        "remote-method": _vm.getRemoteUser,
                        loading: _vm.userLoading
                      },
                      on: { change: _vm.changeUser },
                      model: {
                        value: _vm.newForm.name,
                        callback: function($$v) {
                          _vm.$set(_vm.newForm, "name", $$v)
                        },
                        expression: "newForm.name"
                      }
                    },
                    _vm._l(_vm.userOptions, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: {
                          label: item.ID + "-" + item.name + "-" + item.phone,
                          value: {
                            value: item.ID + "-" + item.name,
                            label: item
                          }
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "模式：", prop: "model", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.newForm.model,
                        callback: function($$v) {
                          _vm.$set(_vm.newForm, "model", $$v)
                        },
                        expression: "newForm.model"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { disabled: _vm.newForm.id > 0, label: 1 } },
                        [_vm._v("预充金")]
                      ),
                      _c(
                        "el-radio",
                        { attrs: { disabled: _vm.newForm.id > 0, label: 2 } },
                        [_vm._v("授信")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "充值金额：", prop: "change_amount" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: { disabled: _vm.newForm.id > 0 },
                    model: {
                      value: _vm.newForm.change_amount,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "change_amount", $$v)
                      },
                      expression: "newForm.change_amount"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "付款凭证：", prop: "resource_cover" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.imageAction,
                        "show-file-list": false,
                        "before-upload": _vm.imageBeforeUpload,
                        "on-success": _vm.handleAvatarSuccess
                      }
                    },
                    [
                      _vm.imageUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imageUrl }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  ),
                  _vm.imageUrl
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { type: "primary", icon: "el-icon-delete" },
                          on: { click: _vm.handleRemove }
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                  _c("span", { staticClass: "help" }, [
                    _vm._v(
                      "图片大小不超过500k（图片仅支持 .jpg, .png, .jpeg格式）"
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注：", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: { type: "textarea", rows: 3, maxlength: "100" },
                    model: {
                      value: _vm.newForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "remark", $$v)
                      },
                      expression: "newForm.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: {
                    click: function($event) {
                      return _vm.handleSave("newForm")
                    }
                  }
                },
                [_vm._v("确定")]
              ),
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取消")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }